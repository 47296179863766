import React from 'react';
import Layout from '../components/Layout';
import Pagination from '@sentry/static-shared/components/Pagination';
import SEO from '../components/SEO';
import { graphql } from 'gatsby';
import Layer from '@sentry/static-shared/components/layer-next';
import BlogHeader from '../components/BlogHeader';
import BlogPostGrid, {
  StyledBlogPostGrid as PostGrid,
} from '../components/BlogPostGrid';

import styled from '@emotion/styled';
import { mqMin, mqMax } from '@sentry/static-shared/utils/css';
import { bgLight, white } from '@sentry/static-shared/utils/css/colors';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { borderRadius } from '../../../shared/utils/css/constants';
import Prose from '@sentry/static-shared/components/Prose';

const CategoryPage = ({
  data: {
    category,
    allContentfulBlogPost,
    contentfulPage: blogData,
    site: {
      siteMetadata: { siteUrl, twitterHandle },
    },
  },
  pageContext,
  location,
}) => {
  const blogHeader = <BlogHeader />;

  return (
    <Layout CustomBlogHeader={blogHeader}>
      <SEO
        title={`Posts in the ${category.title} Category`}
        image={category.metaImage ? category.metaImage.file.url : null}
        canonical={`${siteUrl}${location.pathname}`}
        description={`The category ${category.title} contains ${
          allContentfulBlogPost.edges.length
        } post${
          allContentfulBlogPost.edges.length === 1 ? '' : 's'
        } on the Sentry blog.`}
      />
      <StyledHero
        backgroundImage={
          category.heroImage ? (
            <GatsbyImage image={getImage(category.heroImage)} alt="" />
          ) : null
        }
        paddingBottom={true}
      >
        <PostGrid>
          <StyledHeroBody>
            <StyledCategoryTitle>{category.title}</StyledCategoryTitle>
            {category.body && (
              <StyledCategoryDescription
                dangerouslySetInnerHTML={{
                  __html: category.body.childMarkdownRemark.html,
                }}
              ></StyledCategoryDescription>
            )}
          </StyledHeroBody>
        </PostGrid>
      </StyledHero>
      <StyledMain tag="main" backgroundColor={bgLight} clipTop="jagRight">
        <BlogPostGrid nodes={allContentfulBlogPost.edges.map(x => x.node)} />
        <Pagination pageContext={pageContext} />
      </StyledMain>
    </Layout>
  );
};

export default CategoryPage;

const StyledMain = styled(Layer)`
  flex: 1;
`;

const StyledHeroBody = styled.div`
  background-color: ${white};
  padding: 1.5rem;
  border-radius: ${borderRadius};
`;

const StyledHero = styled(Layer)`
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

const StyledCategoryTitle = styled.h1`
  margin-bottom: 0;
`;

const StyledCategoryDescription = styled(Prose)`
  ${StyledCategoryTitle} + & {
    margin-top: 1rem;
  }
`;

export const query = graphql`
  fragment BlogCategory on ContentfulBlogCategory {
    title
    slug
    metaImage {
      ...Image
    }
    heroImage {
      gatsbyImageData
    }
    body {
      childMarkdownRemark {
        html
      }
    }
  }

  query($slug: String!, $posts: [String!]) {
    site {
      siteMetadata {
        siteUrl
        twitterHandle
      }
    }
    category: contentfulBlogCategory(slug: { eq: $slug }) {
      ...BlogCategory
    }
    allContentfulBlogPost(
      filter: { slug: { in: $posts } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`;
